import { Injectable } from '@angular/core';
import { ApiRequestService } from '../ApiRequest/api-request.service';
import { AdvertisementType } from 'src/app/enums/advertisement/advertisementTypes';

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {

    constructor(
      private _apiRequestService: ApiRequestService,
    ) { }

    public async getAdvertisement(advertisementType: AdvertisementType )
    {
      return await this._apiRequestService.getRequest('advertisement/' + advertisementType)
    }
}
