export const environment = {
    production: true,
    environment : 'production',
    apiUrl: 'https://api.csakjegyek.hu/ticketing-api/',
    client: '',
    clients : {
      test : {
        frontendEncryptDecryptKey: 'XnhwvtQ5D2v7ICX8hcGOsTPcOqy6HYEPr1lvs/Xr3KY=',
      },
      szpari: {
        frontendEncryptDecryptKey: 'biaFVSEbN8whVblSfteO4B49+NUHe0chhL9//xK7So8=',
      },
      banyasz: {
        frontendEncryptDecryptKey: 'F/pv2MYreTq7OmwsDwcN5VOYMoupFxJdN/HhW5qB/TE=',
      }
    }
  };
  