<div id="loader-holder" *ngIf="isLoadingInitially">
    <div class="loader"></div>
</div>
<div *ngIf="!isLoadingInitially" id="featured" [class]="'relative max-h-[531px] h-[80vh] sm:h-[60vh] bg-cover'" [style]="(showMobileBg() ? 'background-image: url(\'' + bg + '\') ' : '')">
    <div
        class="overflow-hidden absolute flex justify-end items-end w-full h-full left-0 bottom-0 from-[var(--primary-color)] bg-gradient-to-t ">
        <div class="video-holder" *ngIf="showDesktopVideo()">
            <video class="absolute z-0 w-full h-full object-cover" preload="auto" [muted]="true" autoplay loop>
                <source [src]="homepage.homepage_background_video" type="video/mp4">
            </video>
        </div>
        <div class="w-2/5 mb-3 sm:w-full sm:max-w-[90%] z-[1] sm:mx-auto">
            <h1 class="text-white font-black text-4xl mb-[10px] pr-4">{{ homepage.homepage_title }}</h1>
            <p class="text-white text-[15px] pb-5 border-b border-b-white mb-5 pr-4">{{ homepage.homepage_subtitle }}</p>
            <app-link-button class="sm:w-full" cssClasses="w-max sm:w-full sm:text-center" [link]="homepage.homepage_url">{{ homepage.homepage_buttontitle }}</app-link-button>
        </div>
    </div>
</div>
<div *ngIf="!isLoadingInitially" class="bg-[var(--primary-color)] sm:mt-4">
    <div class="container">
        <div class="flex justify-between sm:flex-col">
            <section class="mb-5 w-[75%] sm:w-full flex flex-col gap-20">
                <!-- FIXTURES -->
                <div *ngIf="fixtures.upcomingFixtures == undefined || fixtures.upcomingFixtures.length">
                    <h2 class="text-white text-[17px] font-black mb-5 pl-10">Jegyek</h2>
                    <app-fixture-list [fixtures]="fixtures.upcomingFixtures" [needEmptyText]="false"></app-fixture-list>
                </div>
                <!-- FIXTURES -->

                <!-- SEASON PASSES -->
                <div *ngIf="seasonPasses.seasonPasses == undefined || seasonPasses.seasonPasses.length">
                    <h2 class="text-white text-[17px] font-black mb-5 pl-10" >Bérletek</h2>
                    <app-season-pass-list [seasonPasses]="seasonPasses.seasonPasses" [needEmptyText]="false"></app-season-pass-list>
                </div>
                <!-- SEASON PASSES -->
            </section>
            <section class="w-[20%] pt-[45px] sm:w-full">
                <img *ngIf="homepage.homepage_advertisement" [src]="homepage.homepage_advertisement" class="rounded-[20px]" alt="Hirdetés (x)"/>
            </section>
        </div>
    </div>
</div>